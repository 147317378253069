// extracted by mini-css-extract-plugin
export var articleList = "templates-article-list--jcDUx";
export var details = "templates-details--SihJT";
export var headingH3 = "templates-heading--h3--pq8CG";
export var ingredients = "templates-ingredients--QKu95";
export var listCategory = "templates-list-category--crQVl";
export var listCircles = "templates-list-circles--wvYyJ";
export var listInline = "templates-list-inline--q+qLj";
export var listPlain = "templates-list-plain--ZaTCC";
export var listSmColumn = "templates-list-sm-column--mDlXB";
export var listTag = "templates-list-tag--tpjCr";
export var recipeHero = "templates-recipeHero--R5MAY";
export var recipeHeroImage = "templates-recipeHeroImage--nnYMD";
export var recipeHeroWrapper = "templates-recipeHeroWrapper--JYZTH";
export var recipeImage = "templates-recipeImage--I7uJm";
export var recipeLayout = "templates-recipeLayout--bJnhh";
export var recipeTitle = "templates-recipeTitle--flf3s";
export var sectionHeading = "templates-sectionHeading--iUnky";
export var sectionHeadline = "templates-section-headline--+apLB";
export var spacingVBLg = "templates-spacing-v-b-lg--TBjNw";
export var spacingVBMd = "templates-spacing-v-b-md--UM6kQ";
export var spacingVBSm = "templates-spacing-v-b-sm--AADlj";
export var spacingVBZ = "templates-spacing-v-b-z--LI3P-";
export var spacingVTLg = "templates-spacing-v-t-lg--Hs8tq";
export var spacingVTMd = "templates-spacing-v-t-md--exGqa";
export var spacingVTSm = "templates-spacing-v-t-sm--zxn6x";
export var spacingVTZ = "templates-spacing-v-t-z--vsjjt";
export var stickyHeader = "templates-stickyHeader--fXn1-";
export var textLink = "templates-textLink--O2Wgw";
export var type = "templates-type--nlASj";
export var uFlex = "templates-u-flex--SWFt5";
export var uLg1of4 = "templates-u-lg-1of4--zEja7";
export var uLg3of4 = "templates-u-lg-3of4--sktv-";
export var wrapper = "templates-wrapper--2n4av";